.card-skills:hover {
    transform: scale(1.02);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-skills {
    /* background-color: #f1f1f1; */
    border-radius: 8px;
    transition: transform 0.5s ease;
}

.section-title {
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .section-title::after {
    content: '';
    display: block;
    width: 80px;
    height: 3px;
    background-color: #77b849;
    margin: 15px auto 0;
  }

  .card-skills {
    opacity: 0;
  }

  .card-skills.one {
    transition: opacity 0.7s ease-in;
  }

  .card-skills.two {
    transition: opacity 0.9s ease-in;
  }

  .title-card {
    opacity: 0;
  }

  .title-card.one {
    transition: opacity 0.5s ease-in;
  }

  .card-skills.three {
    transition: opacity 1.1s ease-in;
  }

  .card-skills.four {
    transition: opacity 1.3s ease-in;
  }

  .card-skills.five {
    transition: opacity 1.5s ease-in;
  }
  
  .fade-in {
    opacity: 1;
  }