body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .arrows {
    width: 30px;
    height: 48px;
    position: absolute;
    left: 50%;
    margin-left: -16px;
  }
  
  .arrows path {
    stroke: rgb(147, 200, 144);
    fill: transparent;
    stroke-width: 3px;	
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite; 
  }
  
  @keyframes arrow
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
  @-webkit-keyframes arrow /*Safari and Chrome*/
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
  .arrows path.a1 {
    animation-delay:-1s;
    -webkit-animation-delay:-1s; /* Safari 和 Chrome */
  }
  
  .arrows path.a2 {
    animation-delay:-0.5s;
    -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
  }
  
  .arrows path.a3 {	
    animation-delay:0s;
    -webkit-animation-delay:0s; /* Safari 和 Chrome */
  }