.container-hiring {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .container-hiring h1 {
    color: #333;
    font-size: 32px;
  }
  
  .container-hiring p {
    color: #555;
    font-size: 18px;
    line-height: 1.5;
  }

  .container-hiring .desc {
    margin: 20px;
  }
  
  .container-hiring .job-list {
    list-style: none;
    padding: 0;
    text-align: left;
  }
  
  .container-hiring .job-list li {
    margin: 20px;
    border-left: 5px solid green;
    border-right: 5px solid green;
    border-radius: 10px;
    background: #659a623b;
    padding: 5px 15px;
    transition-duration: 0.3s;
    text-align: center;
  }

  .container-hiring .job-list li:hover {
    border-left: 5px solid #9bff95a8;
    border-right: 5px solid #9bff95a8;
    background: #4f7f4c;
  }

  .container-hiring .job-list li:hover .job-title,
  .container-hiring .job-list li:hover .job-description {
    color: white;
  }
  
  .container-hiring .job-title {
    font-weight: bold;
    font-size: 18px;
    color: rgb(0, 117, 85);;
  }
  
  .container-hiring .job-description {
    color: #555;
    font-size: 14px;
  }
  
  .container-hiring .cta-button {
    display: inline-block;
    background-color: #4caf50;
    color: #fff;
    font-size: 18px;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .container-hiring .cta-button:hover {
    background-color: #9bff95a8;
  }