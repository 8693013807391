.about-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: center center;

}

.about-img-container {
    align-self: center;
    width: 400px;
    height: 450px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transform-style: preserve-3d;
    perspective: 1000px;
}

  /* Extra small devices (portrait phones) */
@media (max-width: 576px) {
    .about-header-display {
        font-size: 40px;
    }
  }
  
  /* Small devices (landscape phones) */
  @media (min-width: 576px) and (max-width: 767px) {
    .about-header-display {
        font-size: 30px;
    }
  }
  
  /* Medium devices (tablets) */
  @media (min-width: 768px) and (max-width: 991px) {
    .about-header-display {
        font-size: 45px;
    }
  }
  
  /* Large devices (desktops) */
  @media (min-width: 992px) and (max-width: 1199px) {
    .about-header-display {
        font-size: 60px;
    }
  }
  
  /* Extra large devices (large desktops) */
  @media (min-width: 1200px) {
    .about-header-display {
        font-size: 65px;
    }
  }