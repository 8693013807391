/* Styles for the email form container */
.email-form-container {
  width: 100%;
}

/* Styles for the form elements */
.email-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.email-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333333;
}

.email-form input[type="text"],
.email-form textarea {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.email-form textarea {
  resize: vertical;
}

.email-form button {
  padding: 10px 20px;
  background-color: #659A62;
  color: #ffffff;
  border: none;
  border-radius: 4px !important;
  cursor: pointer;
}

.email-form button:hover {
  background-color: #449944;
}

/* Optional: Add media queries for responsiveness */
@media (max-width: 480px) {
  .email-form-container {
    width: 100%;
  }
}