.ant-menu .ant-menu-item-selected::after,
.ant-menu .ant-menu-item-active::after,
.ant-menu .ant-menu-submenu-selected::after,
.ant-menu .ant-menu-submenu-active::after {
    border-color: #DBF9D8 !important; 
}

.ant-menu .ant-menu-item-selected:hover,
.ant-menu .ant-menu-item-active:hover,
.ant-menu .ant-menu-submenu-selected:hover,
.ant-menu .ant-menu-submenu-active:hover,
.ant-menu .ant-menu-submenu-title:hover,
.ant-menu .ant-menu-submenu-active,
.ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #b3ffac !important;
}

.ant-menu-submenu .ant-menu-item-selected {
    color: green !important;
}

.ant-menu-submenu .ant-menu-item-active:hover {
    color: #659A62 !important;
}

.navbar.theme-button {
    color: white;
    background-color: #659A62;
    border-left: 5px solid #336633;
    border-right: 5px solid #336633;
}

.navbar.close-button {
    color: white;
    background-color: #ff4d4f;
}

.ant-menu-horizontal {
    border-bottom: 0px solid transparent !important;
}

.navbar.theme-button:hover {
    color: #336633;
    background-color: #DBF9D8;
    border-color: #336633;
}

.ant-drawer-content-wrapper {
    width: calc(100%) !important;
}

.ant-drawer-header {
    background-color: rgb(0, 117, 85);
    padding: 0 !important;
}

.ant-drawer-footer {
    /* background-color: rgb(0, 117, 85); */
}